




























import { Component, Model, Vue } from 'vue-property-decorator';
import I18n from '../I18n';
import { Dict } from '../types';
import EduPositionSingleSelection from './EduPositionSingleSelection.vue';
import {staffTabAccess} from "@/modules/budget/staffing-table/common";


const i18n = new I18n('modules.budget.staffing_table.ga_department_positions.*EduPositionSingleCell*');
const modelChangeEvent = 'change';


@Component({
    components: {
        EduPositionSingleSelection,
    },
})
export default class EduPositionSingleCell extends Vue {
    // region Модель, свойства
    @Model(modelChangeEvent, {
        type: Object,
        required: false,
        default: () => null,
    })
    public value!: Dict.EduPosition | null;
    // endregion


    // region Утилиты
    private i18n = i18n;

    private getItemText(item: Dict.EduPosition): string {
      let result = '';
      const appendCode = (code: number | string | null) => {
        if (code === null) {
          return;
        }
        if (result.length > 0) {
          result += ' : ';
        }
        result += code;
      };

      if (item.funcBlock) {
        appendCode(item.funcBlock);
      }

      if (item.level) {
        if (item.level.parent) {
          appendCode(item.level.parent.code);
        }
        appendCode((item.levelNumber !== null) ? item.level.code + `  (${item.levelNumber})` : item.level.code);
      }

      if (result.length > 0) {
        result += ' - ';
      }

      if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
        result += item.nameKk;
      } else {
        result += item.nameRu;
      }

      return result;
    }
    // endregion

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    // region Модальное окно
    private modalVisible = false;
    private modalValue: Dict.EduPosition | null = null;

    private showModal() {
        this.modalValue = this.value;
        this.modalVisible = true;
    }

    private cancelSelection() {
        this.modalVisible = false;
        this.modalValue = null;
    }

    private applySelection() {
        this.modalVisible = false;

        if (this.value?.id !== this.modalValue?.id) {
            this.$emit(modelChangeEvent, this.modalValue);
        }

        this.modalValue = null;
    }
    // endregion
}
