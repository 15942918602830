




























import { Values } from 'vue-i18n';
import { Component, Model, Vue } from 'vue-property-decorator';
import { Dict } from '../types';
import CivilPositionSelection from './CivilPositionSelection.vue';
import {staffTabAccess} from "@/modules/budget/staffing-table/common";


const modelChangeEvent = 'change';


@Component({
    components: {
        CivilPositionSelection
    }
})
export default class CivilPositionCell extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        if (this.value !== null) {
            this.selectedItems = [this.value];
        }

        this.$watch('value', () => {
            if (this.value === null) {
                this.selectedItems = [];
            } else if (this.value.id !== this.selectedItem?.id) {
                this.selectedItems = [this.value];
            }
        });
    }
    // endregion


    // region Модель, свойства
    @Model(modelChangeEvent, {
        type: Object,
        required: false,
        default: null
    })
    public readonly value!: Dict.CivilPosition | null;
    // endregion


    // region Утилиты
    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.*CivilPositionCell*.${key}`, values));
    }

    private getItemFullText(item: Dict.CivilPosition): string {
        let result = '';
        const appendCode = (code: number | string | null) => {
            if (code === null) {
                return;
            }
            if (result.length > 0) {
                result += ' : ';
            }
            result += code;
        };

        if (item.funcBlock) {
            appendCode(item.funcBlock);
        }

        if (item.level) {
            if (item.level.parent) {
                appendCode(item.level.parent.code);
            }
            appendCode((item.levelNumber !== null) ? item.level.code + `  (${item.levelNumber})` : item.level.code);
        }

        if (result.length > 0) {
            result += ' - ';
        }

        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            result += item.nameKk;
        } else {
            result += item.nameRu;
        }

        return result;
    }

    private getItemText(item: Dict.CivilPosition): string { return this.getItemFullText(item); }
    // endregion

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    // region Выбранные элементы
    private selectedItems: Dict.CivilPosition[] = [];

    private get selectedItem(): Dict.CivilPosition | null {
        if (this.selectedItems.isEmpty) {
            return null;
        }
        return this.selectedItems[0];
    }

    private applySelection() {
        if (this.value?.id !== this.selectedItem?.id) {
            this.$emit(modelChangeEvent, this.selectedItem);
        }
    }

    private cancelSelection() {
        if (this.value === null) {
            this.selectedItems = [];
        } else {
            this.selectedItems = [this.value];
        }
    }
    // endregion


    // region Модальное окно
    private modalVisible = false;
    // endregion
}
