












































































































































import { Values } from 'vue-i18n';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { Comp, Dict, Utils } from '../types';
import PaginationPages from '../components/PaginationPages.vue';
import {staffTabAccess} from "@/modules/budget/staffing-table/common";


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRow extends Comp.TableRow<Dict.MvdPosition> {}


const modelChangeEvent = 'change';
const hasDifference = (items1: Dict.MvdPosition[], items2: Dict.MvdPosition[]): boolean => {
    if (items1.length !== items2.length) {
        return true;
    }
    if (items1.length === 0) {
        return false;
    }

    const ids1 = items1.map(item => item.id).sort();
    const ids2 = items2.map(item => item.id).sort();

    for (let i = 0; i < ids1.length; i++) {
        if (ids1[i] !== ids2[i]) {
            return true;
        }
    }

    return false;
};
const itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = [
    { value: 10, text: '10' },
    { value: 25, text: '25' },
    { value: 50, text: '50' },
    { value: 100, text: '100' }
];


@Component({
    components: {
        Pagination: PaginationPages
    }
})
export default class MvdPositionSelection extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.selectedItems = [...this.value];

        this.$watch('value', () => {
            if (hasDifference(this.value, this.selectedItems)) {
                this.selectedItems = [...this.value];
            }
        });

        this.$watch('multiple', () => {
            if ((!this.multiple) && (this.selectedItems.length > 1)) {
                this.selectedItems = [this.selectedItems[0]];
            }
        });

        this.$watch('selectedIds', () => {
            this.rerender();
            if (hasDifference(this.value, this.selectedItems)) {
                this.$emit(modelChangeEvent, [...this.selectedItems]);
            }
        });

        this.$watch('itemsPerPage', () => {
            this.page = 0;
            this.reload();
        });

        this.$watch('searchText', () => {
            this.reload();
        });

        this.$watch('searchCategory', () => {
            this.reload();
        });

        this.$watch('searchTypeOrg', () => {
            this.reload();
        });

        this.$watch('searchLevel', () => {
            this.reload();
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.reload();
    }
    // endregion


    // region Модель, свойства
    @Model(modelChangeEvent, {
        type: Array,
        required: false,
        default: () => []
    })
    public readonly value!: Dict.MvdPosition[];

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly multiple!: boolean;
    // endregion

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    // region Утилиты
    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.*MvdPositionSelection*.${key}`, values));
    }



    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }


    // noinspection JSMethodCanBeStatic
     private getCategoryText(item: Dict.MvdPosition): string | null {
         if (item.category) {
             return item.category;
         }
         return null;
     }

     // noinspection JSMethodCanBeStatic
     private getTypeOrgText(item :Dict.OrganizationTypeMvd | null): string | null {
        if (item?.nameRu) {
             return item.nameRu;
         }
         return null;
     }

    // noinspection JSMethodCanBeStatic
    private getLevelText(item :Dict.MvdPosition | null): number | null {
        if (item?.level) {
            return item.level;
        }
        return null;
    }



     private getItemFullText(item: Dict.MvdPosition): string {
        let positionName: string;
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            positionName = item.nameKk;
        } else {
            positionName = item.nameRu;
        }
        // noinspection JSIncompatibleTypesComparison
         if ((item.category === null)) {
            return positionName;
        }

        return `${item.category}-${positionName}`;
     }

     private getItemText(item: Dict.MvdPosition): string {
         const result = this.getItemFullText(item);

         if (result.length > 50) {
            return result.substring(0, 47) + '...';
         }
        return result;
     }

    // region Выбранные элементы
    private selectedItems: Dict.MvdPosition[] = [];

    private get selectedIds(): Array<number | null> {
        return this.selectedItems.map(item => item.id);
    }

    private unselect(item: Dict.MvdPosition) {
        const index = this.selectedIds.indexOf(item.id);
        if (index >= 0) {
            this.selectedItems.splice(index, 1);
        }
    }

    private toggleRowSelection(row: IRow) {
        if (this.isEditable) {
            const index = this.selectedIds.indexOf(row.data.id);
            if (index >= 0) {
                this.selectedItems.splice(index, 1);
            } else if (this.multiple) {
                this.selectedItems.push(row.data);
            } else {
                this.selectedItems = [row.data];
            }
        }
    }
    // endregion


    // region Навигация по страницам
    private itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = [...itemsPerPageVariants];

    private itemsPerPage = 25;

    private page = 0;

    private totalItems = 0;

    private onPageChanged(page: number) {
        this.page = page;
        this.reload();
    }
    // endregion


    private searchText = '';
    private searchCategory = '';
    private searchTypeOrg = '';
    private searchLevel: number | null = null;

    private getFieldTranslate(i18nKey: string): string {
        return this.getTranslate(`table_fields.${i18nKey}`)
    };

    // region Строки таблицы
    private get fields(): Comp.TableFieldDef[] {
        const dataField = (dataKey: string, i18nKey: string): Comp.TableFieldDef => {
            return {
                key: `data.${dataKey}`,
                label: this.getTranslate(`table_fields.${i18nKey}`)
            };
        };

        return [
            // ID
            dataField('id', 'id'),

            // Категория должности
            dataField('category', 'category'),

            // Тип организации
            dataField('idTypeOrg', 'idTypeOrg'),

            // Уровень должности
            dataField('level', 'level'),

            // Название на русском
            dataField('nameRu', 'name_ru'),

            // Название на казахском
            dataField('nameKk', 'name_kk'),
        ];
    }

    private loading = false;

    private rows: IRow[] = [];

    private renderScheduled = false;

    private rerender() {
        if (this.renderScheduled) {
            return;
        }
        this.renderScheduled = true;
        this.$nextTick(() => {
            this.renderScheduled = false;

            this.rows.forEach(row => {
                row.selected = (this.selectedIds.includes(row.data.id));
                if (row.selected) {
                    row._rowVariant = 'primary';
                } else {
                    row._rowVariant = null;
                }
            });

            this.rows = [...this.rows];
        });
    }

    private reload() {
        if (this.loading) {
            console.warn('Cannot reload rows - another loading is running');
            return;
        }


        this.loading = true;
         this.rows = [];
        Ax<Utils.PaginationList<Dict.MvdPosition>>(
            {
                url: '/api/budget/staffing_table/mvd-positions',
                method: 'GET',
                params: {
                    'search-text': this.searchText,
                    'search-searchCategory': this.searchCategory,
                    'search-searchTypeOrg': this.searchTypeOrg,
                    'search-searchLevel': this.searchLevel,
                    'items-per-page': this.itemsPerPage,
                    'page': this.page
                },
                responseType: 'json'
            },
            list => {

                this.page = list.page;
                this.totalItems = list.itemCount;

                const rows: IRow[] = [];

                list.items.forEach(item => {
                    const row: IRow = {
                        id: String(item.id),
                        data: item,
                        original: null,
                        selected: this.selectedIds.includes(item.id),
                        changed: false,
                        invalid: false,
                        _rowVariant: (this.selectedIds.includes(item.id) ? 'primary' : null),
                        errors: {},
                        inputValues: {}
                    };
                    rows.push(row);
                });

                this.rows = rows;
            },
            error => {
                this.toast('danger', this.getTranslate('error.cannot_load_positions'), error.toString());
            },
            () => {
                this.loading = false;
            }
        );
    }
    // endregion
}
