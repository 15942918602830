
































import { Values } from 'vue-i18n';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import PositionSelection from './PositionSelection.vue';
import { Dict } from '../types';
import {staffTabAccess} from "@/modules/budget/staffing-table/common";


const modelChangeEvent = 'change';


@Component({
    components: {
        PositionSelection
    }
})
export default class PositionCell extends Vue {
    // region Модель, свойства
    @Model(modelChangeEvent, {
        type: Object,
        required: false,
        default: null
    })
    public readonly value!: Dict.Position | null;

    private get valueId(): number | null {
        if (this.value === null) {
            return null;
        }
        return this.value.id;
    }

    @Prop({
        type: Array,
        required: false,
        default: () => []
    })
    public readonly allowedPosLevels!: number[];
    // endregion

    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        if (this.value === null) {
            this.selectedPositions = [];
        } else {
            this.selectedPositions = [this.value];
        }

        this.$watch('modalVisible', () => {
            if (this.modalVisible) {
                if (this.value === null) {
                    this.selectedPositions = [];
                } else {
                    this.selectedPositions = [this.value];
                }
            }
        });
    }
    // endregion

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    // region Утилиты
    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.ga_department_positions.position_cell.${key}`, values));
    }

    private getPositionText(position: Dict.Position): string {
        const parts: string[] = [];
        if (position.category !== null) {
            parts.push(position.category.code);
        }

        if (position.categoryNumber !== null) {
            if (parts.length > 0) {
                parts.push('-');
            }
            parts.push(position.categoryNumber);
        }

        if (parts.length > 0) {
            parts.push(' - ');
        }

        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            parts.push(position.nameKk);
        } else {
            parts.push(position.nameRu);
        }

        return parts.join('');
    }
    // endregion

    // region Основное
    private selectedPositions: Dict.Position[] = [];

    private get selectedPosition(): Dict.Position | null {
        if (this.selectedPositions.length === 0) {
            return null;
        }
        return this.selectedPositions[0];
    }

    private get selectedPositionId(): number | null {
        if (this.selectedPosition === null) {
            return null;
        }
        return this.selectedPosition.id;
    }
    // endregion

    // region Модальное окно
    private modalVisible = false;

    private onModalCancelled() {
        if (this.value === null) {
            this.selectedPositions = [];
        } else {
            this.selectedPositions = [this.value];
        }
    }

    private onModalApplied() {
        if (this.selectedPositionId !== this.valueId) {
            this.$emit(modelChangeEvent, this.selectedPosition);
        }
    }
    // endregion
}
