




























import { Values } from 'vue-i18n';
import { Component, Model, Vue } from 'vue-property-decorator';
import { Dict } from '../types';
import MvdPositionSelection from './MvdPositionSelection.vue';
import {staffTabAccess} from "@/modules/budget/staffing-table/common";


const modelChangeEvent = 'change';


@Component({
    components: {
       MvdPositionSelection
    }
})
export default class MvdPositionCell extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        if (this.value !== null) {
            this.selectedItems = [this.value];
        }

        this.$watch('value', () => {
            if (this.value === null) {
                this.selectedItems = [];
            } else if (this.value.id !== this.selectedItem?.id) {
                this.selectedItems = [this.value];
            }
        });
    }
    // endregion


    // region Модель, свойства
    @Model(modelChangeEvent, {
        type: Object,
        required: false,
        default: null
    })
    public readonly value!: Dict.MvdPosition | null;
    // endregion

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    // region Утилиты
    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.*MvdPositionCell*.${key}`, values));
    }

    private getItemFullText(item: Dict.MvdPosition): string {
        const parts: string[] = [];
        // noinspection JSIncompatibleTypesComparison
        if (item.category !== null) {
            parts.push(item.category + ' : ');
        }
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
             parts.push(item.nameKk);
        } else {
               parts.push(item.nameRu);
        }
        return parts.join('');
    }

    private getItemText(item: Dict.MvdPosition): string { return this.getItemFullText(item); }
    // endregion


    // region Выбранные элементы
    private selectedItems: Dict.MvdPosition[] = [];

    private get selectedItem(): Dict.MvdPosition | null {
        if (this.selectedItems.isEmpty) {
            return null;
        }
        return this.selectedItems[0];
    }

    private applySelection() {
        if (this.value?.id !== this.selectedItem?.id) {
            this.$emit(modelChangeEvent, this.selectedItem);
        }
    }

    private cancelSelection() {
        if (this.value === null) {
            this.selectedItems = [];
        } else {
            this.selectedItems = [this.value];
        }
    }
    // endregion


    // region Модальное окно
    private modalVisible = false;
    // endregion
}
