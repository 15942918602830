






































































































































import { Values } from 'vue-i18n';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import {paramsSerializer, staffTabAccess} from '../common';
import { Overlay, PaginationPages } from '../components';
import { Comp, Dict, Utils } from '../types';
import { IRequestParams } from './types';


// region Local types
/**
 * Строка таблицы
 */
interface IRow {
    /**
     * Должность, загруженная из сервера
     */
    data: Dict.Position;

    /**
     * Признак "строка выбрана"
     */
    selected: boolean;
}
// endregion


// region Utils
const modelChangeEvent = 'change';
const itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '50', value: 50 },
    { text: '100', value: 100 }
];
const hasDifference = (positions1: Dict.Position[], positions2: Dict.Position[]): boolean => {
    if (positions1.length !== positions2.length) {
        return true;
    }

    const position1Ids = positions1.map(position => position.id).sort();
    const position2Ids = positions2.map(position => position.id).sort();

    for (let i = 0; i < position1Ids.length; i++) {
        if (position1Ids[i] !== position2Ids[i]) {
            return true;
        }
    }

    return false;
};
// endregion


@Component({
    components: {
        Overlay,
        Pagination: PaginationPages
    }
})
export default class PositionSelection extends Vue {
    // region Модель и свойства
    @Model(modelChangeEvent, {
        required: false,
        default: () => []
    })
    public readonly value!: Dict.Position[];

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly multiple!: boolean;

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly selectOnRowClick!: boolean;

    @Prop({
        type: Array,
        required: false,
        default: () => []
    })
    public readonly allowedPosLevels!: number[];
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('value', () => {
            if (hasDifference(this.value, this.selectedPositions)) {
                this.selectedPositions = this.value;
            }
        });
        this.selectedPositions = new Array(...this.value);

        this.$watch('selectedPositions', () => {
            if (hasDifference(this.value, this.selectedPositions)) {
                this.$emit(modelChangeEvent, this.selectedPositions);
            }
        });

        this.$watch('searchText', () => {
            this.scheduleSearchTextApply();
        });

        this.$watch('searchCategory', () => {
            this.scheduleSearchTextApply();
        });

        this.$watch('searchFuncBlock', () => {
            this.scheduleSearchTextApply();
        });

        this.$watch('searchPosLevel', () => {
            this.scheduleSearchTextApply();
        });

        this.$watch('searchTextApplyTimeout', (n: number | null, o: number | null) => {
            if (o !== null) {
                clearTimeout(o);
            }
        });

        this.$watch('rowRefreshTimeout', (n: number | null, o: number | null) => {
            if (o !== null) {
                clearTimeout(o);
            }
        });

        this.$watch('itemsPerPage', () => this.reload());
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.reload();
    }
    // endregion


    // region Утилиты
    private getPositionFullText(position: Dict.Position): string {
        let positionName: string;
        if (this.$i18n.locale.trim().toLowerCase() === 'kk') {
            positionName = position.nameKk;
        } else {
            positionName = position.nameRu;
        }

        if ((position.categoryNumber === null) || (position.categoryNumber.length === 0)) {
            return positionName;
        }
        return `${position.category?.code}-${position.categoryNumber} - ${positionName}`;
    }

    private getPositionText(position: Dict.Position): string {
        const result = this.getPositionFullText(position);
        if (result.length > 50) {
            return result.substr(0, 47) + '...';
        }
        return result;
    }

    private getTranslate(key: string, values?: Values): string {
        return String(this.$t(`modules.budget.staffing_table.positions.*PositionSelection*.${key}`, values));
    }

    private getFieldTranslate(i18nKey: string): string {
        return this.getTranslate(`table_fields.${i18nKey}`)
    };

    private getEnumTranslate(enumName: string, value: string): string {
        return String(this.$t(`modules.budget.staffing_table._enum.${enumName}.${value}`));
    }

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private getCategoryText(category: Dict.PositionCategory | null): string | null {
        if (category === null) {
            return null;
        }
        return `${this.getEnumTranslate('position_category_type', 'EMPTY')} ${category.code}`;
    }
    // endregion

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    // region Основное
    private selectedPositions: Dict.Position[] = [];

    private get selectedPositionIds(): number[] {
        const result: number[] = [];

        this.selectedPositions.forEach(position => {
            const id = position.id;
            if (id !== null) {
                result.push(id);
            }
        });

        return result;
    }
    // endregion


    // region Данные поиска
    private searchText = '';
    private searchCategory = '';
    private searchFuncBlock = '';
    private searchPosLevel : number | null = null;

    private optionsFuncBlock = [
        { text: 'Не определен', value: '' },
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'C', value: 'C' },
        { text: 'SCS', value: 'SCS' },
    ];

    private searchTextApplyTimeout: number | null = null;

    private scheduleSearchTextApply() {
        this.searchTextApplyTimeout = setTimeout(() => {
            this.searchTextApplyTimeout = null;
            this.reload();
        }, 500);
    }
    // endregion


    // region Навигация по страницам
    private itemsPerPageVariants: Array<Comp.DropdownItemDef<number>> = new Array(...itemsPerPageVariants);

    private itemsPerPage = 25;

    private page = 0;

    private itemCount = 0;
    // endregion


    // region Строки
    private get fields(): Comp.TableFieldDef[] {
        const createFieldDef = (dataKey: keyof Dict.Position, i18nKey: string): Comp.TableFieldDef => {
            return {
                key: `data.${dataKey}`,
                label: this.getTranslate(`table_fields.${i18nKey}`)
            };
        };

        return [
            // Кнопка выбора строки
            {
                key: 'selected',
                label: ''
            },

            // ID
            createFieldDef('id', 'id'),

            // Вид должности
            createFieldDef('kind', 'kind'),

            // Категория должности
            createFieldDef('category', 'category'),

            // № п/п в категории
            createFieldDef('categoryNumber', 'category_number'),

            // Название на казахском
            createFieldDef('nameKk', 'name_kk'),

            // Название на русском
            createFieldDef('nameRu', 'name_ru'),

            // Функциональный блок
            createFieldDef('funcBlock', 'func_block'),

            // Уровень должности
            createFieldDef('posLevel', 'pos_level')
        ];
    }

    private loading = false;

    // noinspection JSMismatchedCollectionQueryUpdate
    private rows: IRow[] = [];

    private rowRefreshTimeout: number | null = null;

    private refreshRows() {
        this.rowRefreshTimeout = setTimeout(() => {
            this.rowRefreshTimeout = null;
            this.rows.forEach(row => {
                const id = row.data.id;
                row.selected = ((id !== null) && this.selectedPositionIds.includes(id));
            });
            this.rows = new Array(...this.rows);
        });
    }

    private reload() {
        if (this.loading) {
            return;
        }
        const params: IRequestParams = {
            'items-per-page': this.itemsPerPage,
            'page': this.page
        };

        const searchText = this.searchText.trim();
        if (searchText.length > 0) {
            params['search-text'] = searchText.trim();
        }

        const searchCategory = this.searchCategory.trim();
        if (searchCategory.length > 0) {
            params['search-category'] = searchCategory.trim();
        }

        const searchFuncBlock = this.searchFuncBlock.trim().toUpperCase();
        if (searchFuncBlock.length > 0) {
            params['search-funcBlock'] = searchFuncBlock.trim();
        }

        const searchPosLevel = this.searchPosLevel;
        if (searchPosLevel !== null) {
            params['search-posLevel'] = searchPosLevel;
        }

        if (this.allowedPosLevels.length > 0) {
            params['allowed-pos-levels'] = new Array(...this.allowedPosLevels);
        }

        this.loading = true;
        this.rows = [];
        this.searchTextApplyTimeout = null;

        Ax<Utils.PaginationList<Dict.Position>>(
            {
                url: '/api/budget/staffing_table/positions',
                method: 'GET',
                params,
                paramsSerializer
            },
            list => {
                const rows: IRow[] = [];

                list.items.forEach(position => {
                    const row: IRow = {
                        data: position,
                        selected: ((position.id !== null) && this.selectedPositionIds.includes(position.id))
                    };
                    rows.push(row);
                });

                this.itemCount = list.itemCount;
                this.page = list.page;
                this.rows = rows;
            },
            error => this.toast('danger', this.getTranslate('error.cannot_load_positions'), error.toString()),
            () => {
                this.loading = false;
            }
        );
    }

    private toggleSelection(position: Dict.Position) {
        if (this.isEditable) {
            let index: number | undefined;
            for (let i = 0; i < this.selectedPositions.length; i++) {
                const selectedPosition = this.selectedPositions[i];
                if (selectedPosition.id === position.id) {
                    index = i;
                    break;
                }
            }

            if (index !== undefined) {
                this.selectedPositions.splice(index, 1);
            } else {
                if (this.multiple) {
                    this.selectedPositions.push(position);
                } else {
                    this.selectedPositions = [position];
                }
            }

            this.selectedPositions = new Array(...this.selectedPositions);
            this.refreshRows();
        }
    }

    private onRowSelectionChanged(row: IRow) {
        setTimeout(() => {
            this.toggleSelection(row.data);
        });
    }

    private onRowClicked(row: IRow) {
        if (this.selectOnRowClick) {
            this.toggleSelection(row.data);
        }
    }
    // endregion
}
